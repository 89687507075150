<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="1000px"
    title="配置"
  >
    <!-- <div class="searchItem">
      <el-form
        :model="form"
        label-position="center"
        label-width="80px"
        inline
        ref="form"
      >
        <el-form-item label="品牌：">
          <el-input
            style="width: 190px"
            v-model="form.brand"
            placeholder="请输入品牌"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="型号：">
          <el-input
            style="width: 190px"
            v-model="form.model"
            placeholder="请输入型号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="厂商：">
          <el-input
            style="width: 190px"
            v-model="form.producer"
            placeholder="负责人"
          >
          </el-input>
        </el-form-item>
        <div>
          <el-button class="query-btn" type="primary" @click="search()"
            >搜索</el-button
          >
          <el-button type="primary" @click="submit()">确定</el-button>
          <el-button type="primary">取消</el-button>
        </div>
      </el-form>
    </div> -->

    <table-page
      :data="tableData"
      :defaultSelectList="defaultSelectList"
      :columns="columns"
      :border="true"
      @handSelect="handSelect"
      @handSelectAll="handSelectAll"
    >
      <template slot="guidePrice" slot-scope="scope">
        <el-input
          placeholder="采购数量"
          type="number"
          min="1"
          v-model="scope.row.guidePrice"
        >
        </el-input>
      </template>
    </table-page>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
// import { dataList } from "@/api/CollectivePurchase";
import TablePage from "@/components/table/CollectivePurchase-table";
import { dispositionList } from "../memberCenter/CollectionMining/tableColumn";

export default {
  name: "NowDisposition",

  data() {
    return {
      dialogVisible: true,
      tableData: [],
      columns: dispositionList,
      form: {
        brand: "",
        model: "",
        producer: "",
      },
      query: {
        pnum: 1,
        psize: 10,
      },
      ConfigurationList: [], //选择的项目
      tableIndex: "", //配置表格的下标
      defaultSelectList: [], // 保存选中的项目
      agriculturalType: [], //'这是选择的农资种类'
    };
  },
  components: {
    TablePage,
  },
  mounted() {},
  computed: {
    ...mapGetters(["dispositionList"]),
  },
  methods: {
    ...mapMutations(["SET_DISPOSITION"]),
    // 关闭弹窗
    handleClose() {
      this.$emit("closeDialog", false);
    },
    //从新集采页面传过来的配置
    dispositionInit(getList) {
      console.log(getList, "查看getList");
      let dispositionIndex = getList.tableIndex;
      this.tableIndex = getList.tableIndex;

      // console.log(getList, "查看获取到的");
      // if(){

      // }
      if (getList.checkList.length) {
        console.log("有值");
        //判断是否一样

        //这是传到table里回显的数据
        this.defaultSelectList = getList.checkList;

        // //这里对配置的列表换成回显的数据
        // console.log(
        //   getList.dataList[dispositionIndex].List,
        //   "查看 getList.dataList[dispositionIndex].List"
        // );
        let newList = [];
        getList.dataList[dispositionIndex].List.map((item) => {
          let index = getList.checkList.findIndex(
            (its) => item.agriculturalId === its.agriculturalId
          );
          if (index !== -1) {
            console.log(index, "查看index");
            item = getList.checkList[index];
            console.log(item, "修改完的item");
            newList.push({
              ...item,
            });
          } else {
            newList.push({ ...item });
          }
          return newList;
        });

        console.log(newList, "this.newList");
        this.tableData = newList;
        //当没有多选或全选的时候给ConfigurationList赋值
        // this.ConfigurationList = this.defaultSelectLis;
      } else {
        console.log("没有值");
        this.tableData = getList.dataList[dispositionIndex].List;
      }
    },
    // 全选或者反选
    handSelectAll(selection) {
      this.ConfigurationList = selection;
      console.log("这是全选反选数据", selection);
    },
    //多选
    handSelect(selection) {
      this.ConfigurationList = selection;
      console.log("这是多选数据", selection);
    },
    //提交
    submit() {
      let dispositionObj = [];
      if (this.ConfigurationList.length) {
        dispositionObj = {
          index: this.tableIndex,
          List: this.ConfigurationList,
        };
      } else {
        dispositionObj = {
          index: this.tableIndex,
          List: this.tableData,
        };
      }
      console.log(this.tableData, "查看tableData");

      //因为这里没有多选等改变所以this.ConfigurationList为空所以报错，最好的办法是判断一下是否有回显的数据，如果有回显的数据就用回显的数据如果没有回显的数据就用改变的数据
      console.log(dispositionObj, "查看这是什么");
      this.$emit("getConfigurationList", dispositionObj);
      this.$emit("closeDialog", false);
    },

    // async search() {

    //   let params = {
    //     size: this.query.psize,
    //     current: this.query.pnum,
    //     secondId: this.agriculturalType[1],
    //     ...this.form,
    //   };
    //   let res = await dataList(params);

    //   this.tableData = res.data.records;
    // },
  },
};
</script>

<style lang="less" scoped>
/*根据条件搜索*/
.searchItem {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      flex: 1;
      /deep/.el-form-item__content {
        width: 150px;
        .el-input {
          input {
            width: 150px;
          }
        }
      }
    }
  }
  .btn {
    width: 20%;
  }
}
</style>
