<template>
  <div class="newPurchase">
    <h3>发起集采统计</h3>
    <!-- 配置 -->

    <disposition
      v-if="showDisposition"
      @closeDialog="closeDialog"
      @getConfigurationList="getConfigurationList"
      ref="showDisposition"
    />

    <div class="newBox">
      <el-form
        class="login-from-item"
        :model="form"
        label-position="left"
        ref="form"
        :rules="formRules"
      >
        <!-- 标题 -->
        <div class="ProductIntroduction">
          <span>标题</span>
          <el-input
            placeholder="请输入标题"
            v-model="form.title"
            maxlength="500"
          >
          </el-input>
        </div>
        <!-- 集采概要说明 -->
        <div class="ProductIntroduction">
          <span>集采概要说明</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.abstracts"
            maxlength="500"
            type="textarea"
          >
          </el-input>
        </div>
        <!-- 其他说明 -->
        <div class="ProductIntroduction">
          <span>其他说明</span>
          <el-input
            placeholder="请输入内容"
            v-model="form.others"
            maxlength="500"
            type="textarea"
          >
          </el-input>
        </div>
        <!-- 预交费比列 -->
        <div class="ContributionRatio">
          <span>预交费比例</span>
          <el-input placeholder="请输入内容" v-model="form.paymentRatio">
          </el-input>
          <span class="w">%</span>
        </div>
        <!-- 报名时间 -->
        <div class="RegistrationTime">
          <div class="LProductIntroductionItem">
            <span>报名截止时间</span>
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.endTime"
              type="datetime"
              placeholder="请输入截止时间"
            >
            </el-date-picker>
          </div>
          <div class="RProductIntroductionItem">
            <span>预计采购时间</span>
            <el-date-picker
              v-model="form.purchasingPeriodTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请输入"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 集采品种 -->
        <div class="CollectionVariety">
          <div
            class="CollectionVarietyItem"
            v-for="(item, index) in tableList"
            :key="index"
          >
            <div class="ProductIntroduction">
              <span>农资类别</span>
              <el-cascader
                ref="address"
                v-model="item.agriculturalType"
                :options="TypeList"
                @change="handleChange"
                :show-all-levels="false"
                :props="props"
                clearable
              ></el-cascader>
              <div class="del">
                <el-button type="danger" plain @click="deleteBtn(index)"
                  >删除</el-button
                >
              </div>
            </div>
            <div class="specification">
              <div class="l">
                <h3>可选规格</h3>
                <el-button type="primary" plain @click="dispositionBtn(index)"
                  >配置</el-button
                >
              </div>
              <div class="r">
                <table-page
                  :data="item.tableData"
                  :columns="columns"
                  :border="true"
                >
                  <template slot="guidePrice" slot-scope="scope">
                    <el-input
                      placeholder="指导价"
                      type="number"
                      min="mini"
                      style="width: 90px"
                      v-model="scope.row.guidePrice"
                    >
                    </el-input>
                    <span>/{{ scope.row.unit }}</span>
                  </template>
                </table-page>
              </div>
            </div>
          </div>
        </div>
        <!-- 增加集采品种 -->
        <div class="addBtn">
          <el-button type="primary" @click="addBtn">增加集采品种</el-button>
        </div>
        <!-- 相关附件 -->
        <div class="RelatedAnnex">
          <div class="ProductIntroduction">
            <span class="title">其他说明</span>
            <div class="content">
              <!-- <i class="el-icon-upload2"></i> -->
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                name="files"
                :auto-upload="false"
                :on-change="handleChangeUp"
                :file-list="fileList"
                :show-file-list="false"
              >
                <p>
                  <!-- <el-button type="primary">更换</el-button> -->
                  <i class="el-icon-upload2"></i>
                </p>
              </el-upload>
            </div>
            <div class="UpDoc">
              <div class="UpDocTitle">
                <li v-for="(item, index) in fileList" :key="index">
                  <div class="text" @click="Download(item)">
                    {{ item.FilesName }}
                  </div>
                  <div class="del" @click="del(index)">
                    <i class="el-icon-delete"></i>
                  </div>
                </li>
                <!-- <li>技术说明.pdf</li>
                <li>招标说明.doc</li> -->
              </div>
              <div class="Tips">
                *请确认信息输入无误，一经发布，项目信息无法修改
              </div>
            </div>
          </div>
        </div>
        <!-- 预览 -->
        <div class="Preview">
          <div>
            <el-button type="info" @click="closed()">关闭</el-button>
            <el-button type="primary" @click="PreviewClick"> 预览 </el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// 图片上传
import { uploadFile } from "@/api/fileApi";
// 图片下载
import { download } from "@/api/ChargeUp";
import { mapGetters, mapMutations } from "vuex";
import disposition from "./disposition";
import { dataList } from "@/api/CollectivePurchase";
import TablePage from "@/components/table/CallForBids-page";
import { nodeList, treeList } from "@/api/CollectivePurchase";
import { CreateCollection } from "../memberCenter/CollectionMining/tableColumn";
export default {
  name: "NowNewPurchase",

  data() {
    return {
      form: {
        title: "", //标题
        abstracts: "", //摘要
        others: "", //其他说明
        // createTime:'',//开始时间，
        endTime: "", //结束时间，
        purchasingPeriodTime: "", //预计采购时间
        status: "", //状态
        files: "", //文件
        paymentRatio: "", //支付比例
      },
      fileList: [], //上传图片的列表,
      agriculturalType: "", // 选择的类型
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      baseUrl: window.globalUrl.HOME_API,
      formRules: {},
      query: {
        pnum: 1,
        psize: 10,
      },

      TypeList: [],
      // getList:[],// 配置表格的内容
      tableList: [{ agriculturalType: "", tableData: [] }], //这是要预览的列表(需要存vuex)
      props: {
        value: "secondId",
        label: "label",
        children: "children",
      },
      columns: CreateCollection,
      showDisposition: false,
      oneList: [], //一级树状图查询
      subsetList: [], //二级树状图查询
      dataListIndex: 0, // 改变的是哪个的下标
      typeIndex: "", //农资类型最后一级
      dispositionList: [], //配置的内容
      // typeIndexList: [], //农资类型最后一级数组
    };
  },
  components: {
    TablePage,
    disposition,
  },
  computed: {
    ...mapGetters(["CollectionVariety", "formList", "identityObj"]),
  },
  mounted() {
    //node树查询
    this.nodeList();
    this.treeList();
    this.initShow();
    // this.getList();
  },
  methods: {
    ...mapMutations(["SET_TABLElIST", "SET_FORMLIST"]),
    // 关闭弹框
    closeDialog() {
      this.showDisposition = false;
    },
    //配置页传过来的采集信息
    getConfigurationList(dispositionObj) {
      this.tableList[dispositionObj.index].tableData = [...dispositionObj.List];
    },
    deleteBtn(index) {
      this.tableList.splice(index, 1);
    },
    addBtn() {
      this.tableList.push({
        agriculturalType: "",
        tableData: [],
      });
    },
    //组合成一个节点树的列表
    allNode() {
      let firstList = this.oneList;
      let secondList = this.subsetList;
      firstList.forEach((item) => {
        const newItem = {
          ...item,
          children: [],
        };
        secondList.forEach((items) => {
          if (item.id === items.parentId) {
            items.label = items.secondName;
            items.value = items.secondId;
            newItem.children.push(items);
          }
        });
        this.TypeList.push(newItem);
      });
      this.TypeList.forEach((item) => {
        item.secondId = item.value;
      });
    },
    //农资类型下拉框选择
    handleChange(value) {
      this.typeIndex = value;

      if (this.tableList) {
      }
      console.log(value);
    },
    //关闭发起新集采页面
    closed() {
      this.$router.back();
    },
    //附件上传图片的删除
    del(index) {
      let newFileList = this.fileList.filter((item, ind) => {
        if (index == ind) {
          let newFileList = this.fileList.splice(ind, 1);
          return newFileList;
        }
      });
      console.log(newFileList);
    },
    //是否需要回显
    initShow() {
      console.log("执行回显");
      if (this.CollectionVariety.length) {
        this.tableList = this.CollectionVariety;
        this.form = this.formList;
        if (this.formList.files) {
          this.fileList = this.formList.files;
        } else {
          this.fileList = [];
        }
      }
    },
    // 配置弹窗
    async dispositionBtn(index) {
      //查看是否选择农资类别
      if (this.tableList[index].agriculturalType) {
        this.dataListIndex = index;
        //打开配置弹窗
        this.showDisposition = true;

        //获取配置弹窗的信息
        let params = {
          size: this.query.psize,
          current: this.query.pnum,
          secondId: this.tableList[index].agriculturalType[1],
        };
        let res = await dataList(params);

        this.dispositionList[index] = {
          List: res.data.records,
        };

        //判断是否进行配置过，配置过用配置过的值，没有配置过用接口的数据
        if (this.tableList[index].tableData.length) {
          console.log(
            this.tableList[index].agriculturalType,
            "this.tableList[index].agriculturalType"
          );
          console.log(this.typeIndex, "this.typeIndex");
          // 从这里开始判断
          if (this.tableList[index].agriculturalType == this.typeIndex) {
          }
          //这是配置过的值
          let getList = {
            tableIndex: index,
            dataList: this.dispositionList,
            checkList: this.tableList[index].tableData,
            agriculturalType: this.tableList[index].agriculturalType,
          };

          this.$nextTick(() => {
            this.$refs.showDisposition.dispositionInit(getList);
          });
        } else {
          console.log("没有值");
          console.log(this.tableList, "进行打印");

          let getList = {
            tableIndex: index,
            dataList: this.dispositionList,
            checkList: [],
            agriculturalType: this.tableList[index].agriculturalType,
          };
          this.$nextTick(() => {
            this.$refs.showDisposition.dispositionInit(getList);
          });
        }
      } else {
        this.$message.info("请先选择农资类别");
      }
    },
    // 预览
    async PreviewClick() {
      this.$router.push({ name: "Preview" });
      console.log(this.form);
      this.form.files = this.fileList;
      this.SET_TABLElIST(this.tableList);
      this.SET_FORMLIST(this.form);
    },
    //文件上传
    async handleChangeUp(file, fileList) {
      console.log(fileList, "这是fileList");
      let param = new FormData();
      param.append("catalog", "financeAdd");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      console.log(res, "这是res");
      console.log(typeof this.fileList, "查看fileList");
      // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
      let FilesName = res.data.sourceFileName;
      let imgUrl = res.data.url;

      let item = {
        FilesName: FilesName,
        imgUrl: imgUrl,
      };
      this.fileList.push(item);
      console.log(this.fileList, "查看");

      // this.files.push(fileList.name);
    },
    // 农资规格管理-一级种类字典查询
    async nodeList() {
      let params = "first_crop_type";
      let res = await nodeList(params);
      this.oneList = res.data;
    },
    //农资规格管理-种类树状图查询
    async treeList() {
      this.childrenList = [];
      let params = {
        areaCode: this.identityObj.areaCode,
      };
      let res = await treeList(params);
      this.subsetList = res.data;
      this.allNode();
    },
    //下载
    async Download(file) {
      // console.log(file);
      let itemList = file;
      let res = await download({ ossFilePath: itemList.imgUrl });
      if (res) {
        let blob = res;
        let ToName = itemList.FilesName;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.newPurchase {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  h3 {
    padding: 10px 10px;
  }
  .newBox {
    width: 90%;
    margin: 0 auto;
    .ProductIntroduction {
      width: 80%;
      height: 50px;
      display: flex;
      padding-bottom: 10px;
      padding-left: 20px;
      span {
        width: 150px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
    .ContributionRatio {
      width: 80%;
      height: 50px;
      display: flex;
      padding-bottom: 10px;
      padding-left: 20px;
      span {
        width: 127px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .el-input {
        width: 100px;
      }
      .w {
        padding-left: 10px;
      }
    }
    .RegistrationTime {
      padding-left: 20px;
      display: flex;
      .LProductIntroductionItem {
        span {
          width: 127px;
          font-weight: bold;
          flex-direction: column;
          justify-content: space-around;
          display: inline-block;
        }
      }
      .RProductIntroductionItem {
        margin-left: 20px;
        span {
          width: 127px;
          font-weight: bold;
          flex-direction: column;
          justify-content: space-around;
          display: inline-block;
        }
      }
    }
    .CollectionVariety {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      .CollectionVarietyItem {
        margin-top: 10px;
        border: solid 1px #ccc;
        .ProductIntroduction {
          padding-top: 10px;
          width: 95%;
          height: 50px;
          display: flex;
          padding-bottom: 10px;
          span {
            width: 127px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
          .del {
            width: 100%;
            button {
              float: right;
            }
          }
        }
        .specification {
          display: flex;
          padding-left: 20px;
          .l {
            width: 30%;
          }
          .r {
            width: 70%;
            padding-bottom: 10px;
            padding-right: 10px;
          }
        }
      }
    }
    .addBtn {
      margin-top: 20px;
    }
    .RelatedAnnex {
      .ProductIntroduction {
        width: 80%;
        height: 50px;
        display: flex;
        padding-bottom: 10px;
        padding-left: 20px;
        .title {
          width: 150px;
          font-weight: bold;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .content {
          .el-icon-upload2 {
            font-size: 50px;
            font-weight: bold;
          }
        }
        .UpDoc {
          padding-left: 20px;
          .UpDocTitle {
            padding-top: 10px;
            display: flex;
            list-style: none;
            li {
              padding: 0px 8px;
              display: flex;
              .del {
                width: 15px;
                height: 15px;
                i {
                  color: red;
                }
                text-align: center;
              }
              .text {
                text-decoration: underline;
              }
            }
          }
          .Tips {
            padding-top: 10px;
            color: #ec808d;
          }
        }
      }
    }
    .Preview {
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      .el-button {
        width: 150px;
      }
    }
  }
}
</style>
